import "../styles/globals.css";
import { IntercomProvider } from "react-use-intercom";
import NextNProgress from "nextjs-progressbar";

const INTERCOM_APP_ID = "k44qxlux";

export default function MyApp({ Component, pageProps }) {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <Component {...pageProps} />
      <NextNProgress color="#2563eb" options={{ showSpinner: false }} />
    </IntercomProvider>
  );
}
